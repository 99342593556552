import React from 'react';

var img1 = require('./../../images/gallery/pic-1.webp');
var img2 = require('./../../images/gallery/pic-2.webp');
var img3 = require('./../../images/gallery/pic-3.webp');

class Story extends React.Component {
    
    render() {
        
        return (
            <>
                <div className="section-full bg-gray p-tb90 square_shape1 tw-our-story-wrap">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head text-left text-black">
                                <h2 className="text-uppercase font-36">Our Story</h2>
                                <div className="wt-separator-outer">
                                    <div className="wt-separator bg-black" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="section-content our-story">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-media our-story-pic">
                                            <img src={img1.default} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-box our-story-detail">
                                            <h3 className="text-uppercase">2020-21</h3>
                                            <h2 className="text-uppercase">Okayama Shopping Square</h2>
                                            <p>Purpose plan in pune near okayama friendship garden of shopping square.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-box our-story-detail">
                                            <h3 className="text-uppercase">2021-22</h3>
                                            <h2 className="text-uppercase">Office Space</h2>
                                            <p>We design office space for different client where we match there ideology into design and execution. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-media our-story-pic">
                                            <img src={img2.default} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-media our-story-pic">
                                            <img src={img3.default} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-box our-story-detail">
                                            <h3 className="text-uppercase">2021-22</h3>
                                            <h2 className="text-uppercase">Gyms</h2>
                                            <p>Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra a, dapibus at dolor. In iaculis viverra neque, ac eleifend ante lobortis id. In viverra ipsum stie id viverra. </p>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default Story;