import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const members = [
    {
        fullname: 'Ketki Mulki',
        position: 'Customer',
        description: 'We were so impressed with the creativity and attention to detail that Under the Arch brought to our project.',
    },
    {
        fullname: 'Dinesh Kumar',
        position: 'Customer',
        description: 'We had a very challenging renovation project, but Under the Arch was able to help us navigate all the obstacles and stay on budget. We are so happy with the finished product and would definitely use them again.',
    },
    {
        fullname: 'Vrunda Paul',
        position: 'Customer',
        description: 'Pleasure working with Under the Arch, They were able to incorporate our style and needs into the design, and we couldn`t be happier with the results.',
    },
    {
        fullname: 'Ishwar Pawar',
        position: 'Customer',
        description: 'We would definitely recommend them to anyone looking for an architect and interior.',
    },
    {
        fullname: 'Akshay Dhumal',
        position: 'Customer',
        description: 'Under the Arch was able to help us create a beautiful and functional space that we never thought was possible.',
    },
    {
        fullname: 'Chirag Bansal',
        position: 'Customer',
        description: 'We would highly recommend them to anyone looking for an architect who is creative, detail-oriented, and passionate about their work.',
    },
    {
        fullname: 'Priya Kanchi',
        position: 'Customer',
        description: 'Under the Arch  was a pleasure to work with. They were very professional and always delivered on time. We were very happy with the end result, and we would definitely recommend them to anyone looking for an architect in India.',
    }
]


class Testimonials extends React.Component {

    render() {
        const options = {
            loop: true,
            autoplay: false,
            margin: 80,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 2
                }
            }
        };
        return (
            <>
                <div className="section-full p-t140 bg-repeat tm-testimonial-wrap">
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head text-left">
                                <h2 className="text-uppercase font-36">Testimonials</h2>
                                <div className="wt-separator-outer">
                                    <div className="wt-separator bg-black" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL 4 START ON BACKGROUND */}
                            <div className="section-content">
                                <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                                    {members.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="testimonial-6">
                                                <div className="testimonial-text clearfix bg-white">
                                                    <div className="testimonial-detail clearfix">
                                                        <strong className="testimonial-name">{item.fullname}</strong>
                                                        <span className="testimonial-position p-t0">{item.position}</span>
                                                    </div>
                                                    <div className="testimonial-paragraph text-black p-t15">
                                                        <span className="fa fa-quote-left" />
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>Client</strong>
                            <span className="text-black">Says</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials;