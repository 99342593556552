import React,{ useEffect,useState }  from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import generateSlug from './../Utility/slugify';
import config  from '../Common/Config';

var bnrimg = require('./../../images/banner/project-banner.webp');

function Projects()
{
    const [projects, setProjectsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => 
    { 
        const apiUrl = config.apiBaseUrl + 'Get_Projects.php';

        const params = 
        {
            pagetype: 'Projects',
        };

        axios.get(apiUrl, { params })
        .then(response => 
            {
            // Assuming the API returns an array of slider data

            const projectMasterData = response.data.ProjectMaster;

            setProjectsData(projectMasterData);
            
            setLoading(false); // Set loading to false once data is fetched
        })
        .catch(error => 
            {
            console.error('Error fetching data:', error);
            setLoading(false); // Set loading to false once data is fetched
        });
    }, []);

    
    const options = {
        loop:true,
        autoplay:false,
        center: true,
        items:3,
        margin:0,
        nav:true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1
            },
            854:{
                items:2
            }
        }
    };
    return (
        <>
            <Header2 />
            <div className="page-content">
                <Banner title="Improving quality of life with an integrated unified approach." pagename="Projects" bgimage={bnrimg.default}/>
                {/* SECTION CONTENT START */}
                <div className="section-full p-tb90 bg-repeat" style={{ backgroundImage: 'url(images/background/ptn-1.png)' }}>
                    <div className="container-fluid">
                        <div className="section-content">
                            <div className="work-carousel-outer">
                            <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                {projects.map((item, index) => 
                                {  
                                const projectslug = generateSlug(item.name);

                                const projectdetailpageurl = "/project/"+projectslug +'/'+item.id;

                                const projectimage = config.imagePath + 'Project/' + item.image_url1;

                               return (
                              
                                    <div key={index} className="item" >
                                        <NavLink to={projectdetailpageurl}>
                                        <div className="wt-img-effect ">
                                            <img src={projectimage} alt="" />
                                            <div className="overlay-bx-2 ">
                                                <div className="line-amiation p-a40">
                                                    <div className="text-white  font-weight-300">
                                                        <h2 className="text-black font-36 letter-spacing-2 text-uppercase text-white">{item.name}</h2>
                                                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                        <NavLink to={projectdetailpageurl} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </NavLink>
                                    </div>
                                 
                                )})}

                            </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END  */}
            </div>
            <Footer />
        </>
    );
}

export default Projects;