import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';


const Footer = () => 
{
    const [logo, setLogo] = useState(require('./../../images/logo-blk.webp').default);

    const updateFooterLogo = (updatedLogo) => {
        setLogo(updatedLogo.default);
    };

    return (
        <>
            <footer className="site-footer bg-gray footer-light footer-wide">
                <div className="footer-bottom overlay-wraper">
                    <div className="overlay-main bg-black" style={{ opacity: 0 }} />
                    <div className="container p-t30">
                        <div className="row">
                            <div className="col-lg-4 col-md-3 wt-footer-bot-left">
                                <NavLink to={"/"}>
                                    <img src={logo} alt="" width={80} height={80} />
                                </NavLink>
                            </div>
                            <div className="col-lg-4 col-md-4 text-center copyright-block p-t60">

                            Design and developed by <a href="https://www.nurasoftech.com/Best_Web_Development_Company_in_Pune.php" target="_blank"  className="footerurl">Nura Softech Pvt Ltd.</a>
                
                            </div>
                            <div className="col-lg-4 col-md-5 wt-footer-bot-right p-t60">
                                <ul className="copyrights-nav pull-right">
                                    <li><NavLink to={"/term-and-condition"}>Terms & Condition</NavLink></li>
                                    <li><NavLink to={"/privacypolicy"}>Privacy Policy</NavLink></li>
                                    <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
