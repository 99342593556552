import React,{ useEffect,useState }  from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import config  from '../Common/Config';

function ClientsLogoNew() 
{
  const [clients, setClientsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => 
  {
    const apiUrl = config.apiBaseUrl + 'Get_Clients.php';
    // Fetch data from your API here
    axios.get(apiUrl)
      .then(response => 
        {
        // Assuming the API returns an array of slider data

        const ClientMasterData = response.data.ClientMaster;

        setClientsData(ClientMasterData);
        
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false once data is fetched
      });
  }, []);
   // The empty dependency array [] means this effect runs once after the initial render
   const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    responsive: {
        0: {
            items: 2
        },
        480: {
            items: 3
        },
        767: {
            items: 4
        },
        1000: {
            items: 5
        }
    }
    };
    return (
        <>
            <div className="section-full p-tb40 bg-black square_shape4 tm-client-wrap">
                <div className="container">
                    <div className="section-content">
                        {/* TESTIMONIAL 4 START ON BACKGROUND */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="text-left">
                                        <h2 className="text-uppercase font-36 text-white">Our Clients</h2>
                                        <div className="wt-separator-outer">
                                            <div className="wt-separator bg-white" />
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                </div>
                                <div className="col-md-8 col-sm-12">
                                    <div className="section-content bg-white p-tb10">
                                        <OwlCarousel className="owl-carousel home-client-carousel owl-btn-center-v" {...options}>
                                            {clients.map((item, index) =>
                                            {
                                                const clientimage = config.imagePath + 'Client/' + item.imageurl;
                                                return (
                                                <div className="item" key={index}>
                                                    <div className="ow-client-logo">
                                                        <div className="client-logo client-logo-media">
                                                            <img src={clientimage} alt="" /></div>
                                                    </div>
                                                </div>
                                            )})}
                                        </OwlCarousel>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClientsLogoNew;
