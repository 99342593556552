import React,{ useEffect,useState }  from 'react';
import { useParams } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import axios from 'axios';
import config  from '../Common/Config';
import { format } from 'date-fns';

var bnrimg = require('./../../images/banner/news-banner.webp');

function NewsDetail()
{
    const {newsId } = useParams();

    const [title, settitle] = useState([]);
    const [short_description, setshort_description] = useState([]);
    const [description, setdescription] = useState([]);
    const [main_image_url, setmain_image_url] = useState([]);
    const [detail_image_url, setdetail_image_url] = useState([]);
    const [creationdate, setcreationdate] = useState([]);

    const [loading, setLoading] = useState(true);

    const fetchData = async () => 
    {
      const apiUrl = config.apiBaseUrl + 'Get_News_Detail.php';

      const params = 
      {
          newsid: newsId,
      };

      try {
                  const response = await axios.get(apiUrl, { params }); // Replace with your API endpoint
                  const Status=response.data.Status;

                  if(Status === 1)
                  {
                      const newsdetailData = response.data.NewsDetailMaster[0];

                      settitle(newsdetailData.title);
                      setshort_description(newsdetailData.short_description);
                      setdescription(newsdetailData.description);

                      const newsimage1 = config.imagePath + 'News/' + newsdetailData.main_image_url;

                      const newsimage2 = config.imagePath + 'NewsDetail/' + newsdetailData.detail_image_url;

                      setmain_image_url(newsimage1);

                      setdetail_image_url(newsimage2);

                      const validDate = new Date(newsdetailData.creationdate);
                      const formattedDate = format(validDate, 'MMMM dd, yyyy');

                      setcreationdate(formattedDate);
                  }
                  else
                  {
                  console.log(response.data.Message);
                  }
              setLoading(false); 
        } catch (error) 
        {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
    };

    useEffect(() => 
    {
        fetchData();
    }, []);

    return (
        <>
            <Header2 />
            <div className="page-content">
                <Banner title="The inside scoop on architecture and interior design" pagename={title} bgimage={bnrimg.default}/>
                {/* SECTION CONTENT START */}
                <div className="section-full p-tb90 tm-work-detail-wrap">
                    <div className="container">
                        <div className="project-detail-outer bg-top-left bg-parallax bg-center" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(' + detail_image_url + ')' }}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 project-detail-pic" />
                                <div className="col-md-6 col-sm-12 project-detail-containt bg-black square_shape3">
                                    <div className="p-lr20 p-tb80">
                                        <div className="bg-white p-lr30 p-tb50 text-black">
                                            <h2 className="m-t0"><span className="font-34 text-uppercase">{title}</span></h2>
                                            <p>{creationdate}</p>
                                            <p>{short_description}</p>
                                            <div className="newsdesc" dangerouslySetInnerHTML={{ __html: description }} />
                                            {/* <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6 m-b30">
                                                        <h5 className="text-uppercase">Date</h5>
                                                        <p>January 04, 2022</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 m-b30">
                                                        <h5 className="text-uppercase">Client</h5>
                                                        <p>Branding NthPsd Company</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 m-b30">
                                                        <h5 className="text-uppercase">Project type</h5>
                                                        <p>Contruction, Brading</p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 m-b30">
                                                        <h5 className="text-uppercase">Creative Director</h5>
                                                        <p>Lorem Ipsum doler</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="p-t0">
                                                <ul className="social-icons social-square social-darkest m-b0">
                                                    <li><a target="_blank" href="https://www.facebook.com" className="fa fa-facebook" /></li>
                                                    <li><a target="_blank"
                                                     href="https://twitter.com" className="fa fa-twitter" /></li>
                                                    <li><a target="_blank"
                                                     href="https://in.linkedin.com" className="fa fa-linkedin" /></li>
                                                    <li><a target="_blank"
                                                     href="https://www.instagram.com/" className="fa fa-instagram" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END  */}
            </div>

            <Footer />
        </>
    );
}

export default NewsDetail;