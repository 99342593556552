import React,{ useEffect,useState }  from 'react';
import { useParams } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import axios from 'axios';
import config  from '../Common/Config';

var bnrimg = require('./../../images/banner/project-banner.webp');

function ProjectDetail()
{
    const {projectId } = useParams();
    
    const [bigprojectimage1, setBigProjectImage1] = useState([]);
    const [bigprojectimage2, setBigProjectImage2] = useState([]);

    const [image_url1, setimage_url1] = useState([]);
    const [image_url2, setimage_url2] = useState([]);
    const [image_url3, setimage_url3] = useState([]);
    const [image_url4, setimage_url4] = useState([]);
    
    const [name, setname] = useState([]);
    const [title, settitle] = useState([]);
    const [description, setdescription] = useState([]);

    const [loading, setLoading] = useState(true);

    const fetchData = async () => 
      {
        const apiUrl = config.apiBaseUrl + 'Get_Project_Detail.php';

        const params = 
        {
            projectid: projectId,
        };

        try {
                    const response = await axios.get(apiUrl, { params }); // Replace with your API endpoint
                    const Status=response.data.Status;

                    if(Status === 1)
                    {
                        const projectdetailData = response.data.ProjectDetailMaster[0];
                         
                        let bigimage_url1 = "";

                        if(projectdetailData.image_url1)
                        {
                            bigimage_url1 = config.imagePath + 'Project/' + projectdetailData.image_url1;
                        }
                    

                        let bigimage_url2 = "";

                        if(projectdetailData.image_url2)
                        {
                            bigimage_url2 = config.imagePath + 'Project/' + projectdetailData.image_url2;
                        }
                      
                        
                        setBigProjectImage1(bigimage_url1);
                        setBigProjectImage2(bigimage_url2);

                        let main_image_url = "";

                        if(projectdetailData.main_image_url)
                        {
                            main_image_url = config.imagePath + 'Project/' + projectdetailData.main_image_url;
                        }
                     
                        let image_url3 = "";

                        if(projectdetailData.image_url3)
                        {
                            image_url3 = config.imagePath + 'Project/' + projectdetailData.image_url3;
                        }
                     
                        let image_url4 = "";

                        if(projectdetailData.image_url4)
                        {
                            image_url4 = config.imagePath + 'Project/' + projectdetailData.image_url4;
                        }
                    
                        let image_url5 = "";

                        if(projectdetailData.image_url5)
                        {
                            image_url5 = config.imagePath + 'Project/' + projectdetailData.image_url5;
                        }
                      
                        setimage_url1(main_image_url);
                        setimage_url2(image_url3);
                        setimage_url3(image_url4);
                        setimage_url4(image_url5);

                        setname(projectdetailData.name);
                        settitle(projectdetailData.title);
                        setdescription(projectdetailData.description);
                    }
                    else
                    {
                    console.log(response.data.Message);
                    }
                setLoading(false); 
          } catch (error) 
          {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
      };

    useEffect(() => 
    {
        fetchData();

        const loadScript = (src) => 
{
  return new Promise(function (resolve, reject) 
  {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function () 
    {
      resolve();
    });
    script.addEventListener('error', function (e) 
    {
      reject(e);
    });
    document.body.appendChild(script);
    document.body.removeChild(script);
  });
            };

        loadScript('./assets/js/custom.js');
    }, []);

        if (loading) 
        {
        return <div>Loading...</div>;
        }

            return (
                <>
                    <Header2 />
                    <div className="page-content">
                        <Banner title={title} pagename={name} bgimage={bnrimg.default}/>
                        
                        {/* SECTION CONTENT */}
                        <div className="section-full p-tb90 tm-work-wrap">
                            {/* GALLERY CONTENT START */}
                            <div className="container-fluid">
                                <div className="portfolio-wrap mfp-gallery work-masonry clearfix">
                                    <div className="row">
                                        
                                    {image_url1 ? (
                                            <div className={`col-lg-3 masonry-item col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={image_url1} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="text-white">
                                                                <h2 className="text-white font-24 font-weight-300"> <div dangerouslySetInnerHTML={{ __html: description }} /></h2>
                                                                {/* <NavLink to={"/project-detail"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null} 

                                     {bigprojectimage1 ? (
                                            <div className={`col-lg-6 masonry-item col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={bigprojectimage1} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="text-white">
                                                            <h2 className="text-white font-24 font-weight-300"> <div dangerouslySetInnerHTML={{ __html: description }} /></h2>
                                                                {/* <NavLink to={"/project-detail"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null}

                                    {image_url2 ? (
                                            <div className={`col-lg-3 masonry-item col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={image_url2} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="text-white">
                                                            <h2 className="text-white font-24 font-weight-300"> <div dangerouslySetInnerHTML={{ __html: description }} /></h2>
                                                                {/* <NavLink to={"/project-detail"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null}

                               {image_url3 ? (
                                            <div className={`col-lg-3 masonry-item col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={image_url2} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="text-white">
                                                            <h2 className="text-white font-24 font-weight-300"> <div dangerouslySetInnerHTML={{ __html: description }} /></h2>
                                                                {/* <NavLink to={"/project-detail"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null}

                                {bigprojectimage2 ? (
                                            <div className={`col-lg-6 masonry-item col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={bigprojectimage2} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="text-white">
                                                            <h2 className="text-white font-24 font-weight-300"> <div dangerouslySetInnerHTML={{ __html: description }} /></h2>
                                                                {/* <NavLink to={"/project-detail"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null}

                            

                                     {image_url4 ? (
                                            <div className={`col-lg-3 masonry-item col-md-6 col-sm-6 m-b30`}>
                                            <div className="wt-img-effect ">
                                                <img src={image_url2} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="text-white">
                                                            <h2 className="text-white font-24 font-weight-300"> <div dangerouslySetInnerHTML={{ __html: description }} /></h2>
                                                                {/* <NavLink to={"/project-detail"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : null}
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            {/* GALLERY CONTENT END */}
                        </div>
                        {/* SECTION CONTENT END */}
                    </div>
    
                    <Footer />
                </>
            );
        
    
  
    
}

export default ProjectDetail;