import React,{ useEffect,useState }  from 'react';
import { NavLink, } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import generateSlug from './../Utility/slugify';
import config  from '../Common/Config';
import { format } from 'date-fns';

const filters = [
  { label: "House", filter: ".cat-1" },
  { label: "Building", filter: ".cat-2" },
  { label: "Office", filter: ".cat-3" },
];

var bnrimg = require('./../../images/banner/news-banner.webp');

const loadScript = (src) => 
{
  return new Promise((resolve, reject) => 
  {
    const script = document.createElement('script');
    script.src = src;

    script.addEventListener('load', () => {
      document.body.removeChild(script); // Remove the script after it has loaded
      resolve();
    });

    script.addEventListener('error', (e) => {
      document.body.removeChild(script); // Remove the script if there's an error
      reject(e);
    });

    document.body.appendChild(script);
  });
};

function News() 
{
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  loadScript('./assets/js/custom.js');

  useEffect(() => 
  {
    // Define an async function within useEffect
    async function fetchData() 
    {
      try {
        const apiUrl = config.apiBaseUrl + 'Get_News.php';

        const response = await fetch(apiUrl);
        if (!response.ok) 
        {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        setData(data.NewsMaster);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) 
      {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    }

    // Call the async function to fetch data
    fetchData();
  }, []); // The empty dependency array ensures this effect runs once when the component mounts

  if (loading) 
  {
    return <div>Loading...</div>;
  }

  if (data) 
  {
    // Render your component based on the fetched data
    return (
      <div>
        <Header2 />
        <div className="page-content">
          <Banner
            title="The inside scoop on architecture and interior design"
            pagename="News"
            bgimage={bnrimg.default}
          />

        <div className="section-full p-t40 tm-work-wrap">
          <div className="container">
            {/* PAGINATION START */}
            <div className="filter-wrap p-b50">
              <ul className="masonry-filter link-style text-uppercase">
                <li className="active">
                  <NavLink to={"#"} data-filter="*" data-hover="All">
                    All
                  </NavLink>
                </li>
                {filters.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={"#"}
                      data-filter={item.filter}
                      data-hover={item.label}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>


        <div className="portfolio-wrap mfp-gallery news-grid clearfix">
          <div className="container-fluid">
            <div className="row">
              
              {data.map((item, index) =>  { 
                
                const newsslug = generateSlug(item.title);

                const newsdetailpageurl = "/news/"+newsslug +'/'+item.id;

                const newsimage = config.imagePath + 'News/' + item.main_image_url;

                const validDate = new Date(item.creationdate);
                const formattedDate = format(validDate, 'dd MMM yyyy');

                return(
                <NavLink to={newsdetailpageurl} > 
                <div key={index} className={`cat-${item.categoryid} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}>
                  <div className="wt-img-effect ">
                    <img src={newsimage} alt="" />
                    <div className="overlay-bx-2 ">
                      <div className="line-amiation  p-a40">
                        <div className="wt-post-info text-white">
                          <div className="wt-post-title ">
                            <h2 className="post-title text-white font-18 letter-spacing-4 font-weight-600">
                                {item.title}
                            </h2>
                          </div>
                          <div className="wt-post-meta ">
                            <ul>
                              <li className="post-date">
                                <span>{formattedDate}</span>
                              </li>
                              <li className="post-author">
                                  By <span>admin</span>
                              </li>
                            </ul>
                          </div>
                          <div className="wt-post-text">
                            <p>{item.description}</p>
                          </div>
                          <NavLink
                            to={newsdetailpageurl}
                            className="v-button letter-spacing-4 font-12 text-uppercase p-l20"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </NavLink> 
              )})}
            
            </div>
          </div>
        </div>
        </div>
      <Footer />
    </div>
    );
  }

  return null; // You can also return null or some other fallback UI when data is not available
}

export default News;
