import React, { useEffect,useState } from 'react';
import { NavLink,useLocation  } from 'react-router-dom';

const Navigation = () => {

    const location = useLocation();

    const isActiveLink = (match, location) => 
    {
        // Replace "/your-path" with the path you want to match
        return location.pathname === "/your-path";
    };

    useEffect(() => {

        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript('./assets/js/custom.js');
    }, []);

    return (
        <ul className="nav navbar-nav">
            <li className={window.location.pathname === "/"  ? "active" : ""}>
                <NavLink to={"/"} >Home</NavLink>
            </li>
            <li className={window.location.pathname === "/news" ? "active" : ""}>
                <NavLink to={"/news"} >News</NavLink>
            </li>
            <li  className={window.location.pathname === "/projects"  ? "active" : ""}>
                <NavLink to={"#"} >Works</NavLink>
                <ul className="sub-menu v-sub-menu">
                    <li  className={window.location.pathname === "/projects" ? "active" : ""}>
                        <NavLink to={"/projects"}>Projects</NavLink>
                    </li>
                </ul>
            </li>
            <li  className={window.location.pathname === "/blogs"  ? "active" : ""}> 
                <NavLink to={"/blogs"} >Blogs</NavLink>
            </li>
            <li className={window.location.pathname === "/aboutus" ? "active" : ""}>
                <NavLink to={"/aboutus"} >About us</NavLink>
            </li>
            <li className={`submenu-direction ${window.location.pathname === "/contactus" ? "active" : ""}`}>
                <NavLink to={"/contactus"}>Contact us</NavLink>
            </li>
        </ul>
    );
};

export default Navigation;
