import React,{ useEffect,useState }  from 'react';
import { useParams } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import BlogSidebar from './../Segments/BlogSidebar';
import axios from 'axios';
import { format } from 'date-fns';
import config  from '../Common/Config';
import ShareOnWhatsApp from '../Segments/ShareOnWhatsApp';
import ShareOnTwitter from '../Segments/ShareOnTwitter';
import ShareViaEmail from '../Segments/ShareViaEmail';
import ShareOnFacebook from '../Segments/ShareOnFacebook';

var bnrimg = require('./../../images/banner/blog-banner.webp');

function BlogDetail()
{
    const { slug, blogId } = useParams();

    const [title, settitle] = useState([]);

    const [main_blog_image, setblogimage1] = useState([]);
    const [large_blog_image, setblogimage2] = useState([]);

    const [youtube_link, setyoutube_link] = useState([]);

    const [short_description, setshortdescription] = useState([]);
    const [full_description, setfulldescription] = useState([]);
    const [creationdate, setcreationdate] = useState([]);

    const [current_blogurl, setcurrent_blogurl] = useState([]);

    const [loading, setLoading] = useState(true);
    
    const fetchData = async () => 
    {
      const apiUrl = config.apiBaseUrl + 'Get_Blog_Detail.php';

      const params = 
      {
        blogid: blogId,
      };

      try {
                  const response = await axios.get(apiUrl, { params }); // Replace with your API endpoint
                  const Status=response.data.Status;

                  if(Status === 1)
                  {
                    const blogdetailData=response.data.BlogDetailMaster[0];
              
                    setyoutube_link(blogdetailData.youtube_link);
      
                    settitle(blogdetailData.title);

                    const blogimage1 = config.imagePath + 'Blog/' + blogdetailData.main_image_url;

                    setblogimage1(blogimage1);

                    const blogimage2 = config.imagePath + 'Blog/' + blogdetailData.large_image_url;
      
                    setblogimage2(blogimage2);
      
                    setshortdescription(blogdetailData.short_description);
                        
                    setfulldescription(blogdetailData.description);
      
                    const validDate = new Date(blogdetailData.creationdate);
                    const formattedDate = format(validDate, 'dd MMM yyyy');
      
                    setcreationdate(formattedDate);

                    const current_url = window.location.href;

                    // const current_url = "/blog/"+slug +'/'+blogId;

                    setcurrent_blogurl(current_url);
                  }
                  else
                  {
                  console.log(response.data.Message);
                  }
              setLoading(false); 
        } catch (error) 
        {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
    };

    useEffect(() => 
    {
        if(blogId)
        {
            fetchData();
        }
     
    }, [blogId]); 


        return (
            <div>
                <Header2 />
                <div className="page-content">
                <Banner title="Your home, your style." pagename={title} bgimage={bnrimg.default} />
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="blog-post blog-lg date-style-1 text-black">
                                        <div className="wt-post-media">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe
                                            src={youtube_link}
                                            className="embed-responsive-item"
                                            width={1000}
                                            height={563}
                                            />
                                            </div>
                                          
                                        </div>
                                        <div className="wt-post-info p-t30 bg-white">
                                            <div className="wt-post-title ">
                                                <h1 className="post-title"><span className="text-black font-30 letter-spacing-1 font-weight-600">{title}</span></h1>
                                            </div>
                                            <div className="wt-post-meta">
                                                <ul>
                                                    <li className="post-date">  <span> {creationdate}</span> </li>
                                                </ul>
                                            </div>
                                            <div className="wt-post-text">
                                        
                                                <div dangerouslySetInnerHTML={{ __html: full_description }} />
                                            </div>
                                        </div>
                                    </div>
                          
                                    <div className="wt-box">
                                        <div className="row  p-lr15">
                                            <h4 className="tagcloud text-uppercase">Share this Post:</h4>
                                            <div className="widget_social_inks">
                                                <ul className="social-icons social-md social-square social-dark m-b0">

                                                   <ShareOnFacebook title={title} blogUrl={current_blogurl} />

                                                   <ShareOnTwitter
                                                   caption={title}
                                                   image={main_blog_image}
                                                   blogPostUrl={current_blogurl}
                                                   />

                                                    <ShareViaEmail subject={title} body={current_blogurl} email='' />

                                                    <ShareOnWhatsApp
                                                        caption={title}
                                                        image={main_blog_image}
                                                        blogPostUrl={current_blogurl}
                                                    />
                       
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wt-divider divider-3px bg-gray-dark"><i className="icon-dot c-square" /></div>
                                    
                                </div>
                                <BlogSidebar blogid={blogId} blogimage={main_blog_image} blogtitle={title} short_description={short_description}/>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>
    
                <Footer />
            </div>
        );
    
    }

   

export default BlogDetail;