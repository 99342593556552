import React ,{ useState,useRef } from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import axios from 'axios';
import config  from '../Common/Config';

var bnrimg = require('./../../images/banner/contact-banner.webp');
var embedUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30261.237562458515!2d73.91145!3d18.5445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c172f26df47d%3A0x8d508903a17ab0c1!2sUrbanFit%20Wellness%20Private%20Limited!5e0!3m2!1sen!2sin!4v1694171868596!5m2!1sen!2sin`;

function  ContactUs()
{
    const mobileNumberRef = useRef(null);


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobileno: '',
        message: '',
      });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleSubmit = async (e) => 
      {
        e.preventDefault();

        const mobileNo = formData.mobileno;
        if (!isValidMobileNumber(mobileNo)) 
        {
          setErrorMessage('Mobile number must be a 10-digit number.');
          mobileNumberRef.current.focus();
          return;
        }

        try 
        {
          const apiUrl = config.apiBaseUrl + 'Contact_Enquiry.php';

          const configparameter = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          };

          const response = await axios.post(apiUrl, formData, configparameter);
              
        //   console.log('API response:', response.data);

          const Status=response.data.Status;

          if(Status===1)
          {
            setFormData({
                name: '',
                email: '',
                mobileno: '',
                message: '',
              });
    
              setSuccessMessage("success");

              setTimeout(() => {
                setSuccessMessage('');
              }, 5000);
          }
          else if(Status===2)
          {
            setErrorMessage("Mobile number is already use for enquiry.");
          }
          else if(Status===0)
          {
            setErrorMessage("Message is not submited try again.");

            setTimeout(() => {
                setErrorMessage('');
              }, 5000);
          }

          // Optionally, you can reset the form after successful submission
          

        } catch (error) 
        {
          setErrorMessage("error");
          console.error('API error:', error);
        }
      };

      // Function to validate mobile number as 10 digits
        function isValidMobileNumber(mobileNo) 
        {
            // Remove non-digit characters
            const cleanedNumber = mobileNo.replace(/\D/g, '');
            return cleanedNumber.length === 10;
        }
      
    return (
        <>
            <Header2 />
            <div className="page-content">
            <Banner title="Turning spaces into dreams" pagename="Contact us" bgimage={bnrimg.default}/>
                {/* SECTION CONTENTG START */}
                <div className="section-full p-tb80 tm-shortcode-wrap">
                    {/* LOCATION BLOCK*/}
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head text-left text-black">
                            <h2 className="text-uppercase font-36">Where to Find us </h2>
                            <div className="wt-separator-outer">
                                <div className="wt-separator bg-black" />
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                            {/* CONTACT FORM*/}
                            <div className="wt-box">
                                <form  onSubmit={handleSubmit}>
                                    <div className="contact-one p-a40 p-r150">
                                        <div className="form-group">
                                            <input name="name" type="text" className="form-control" placeholder="Name" value={formData.name} onChange={handleChange} required />
                                        </div>
                                        <div className="form-group">
                                            <input name="email" type="email" className="form-control"  placeholder="Email" value={formData.email} onChange={handleChange} required />
                                        </div>

                                       <div className="form-group">
                                            <input name="mobileno" type="tel"    pattern="[6789][0-9]{9}" className="form-control"  placeholder="Mobile No" minlength="10" maxlength="10"  value={formData.mobileno} onChange={handleChange}  ref={mobileNumberRef}  required/>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" rows={3} className="form-control" placeholder="Message"  value={formData.message} onChange={handleChange} defaultValue={""} />
                                        </div>
                                        <button  type="submit" value="Submit" className="site-button black radius-no text-uppercase">
                                            <span className="font-12 letter-spacing-5">Submit</span>
                                        </button>

                                        {errorMessage && <div className="alert alert-danger fade in  m-t10">
                                                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                                                {errorMessage}
                                            </div>}
                                        {successMessage && <div className="alert alert-success fade in m-t10">
                                                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                                                <strong>Thank you </strong> our team contact you shortly!
                                            </div>}

                                        <div className="contact-info bg-black text-white p-a30">
                                            <div className="wt-icon-box-wraper left p-b30">
                                                <div className="icon-sm"><i className="iconmoon-smartphone-1" /></div>
                                                <div className="icon-content text-white ">
                                                    <h5 className="m-t0 text-uppercase">Phone number</h5>
                                                    <p>(+91) 9209276819 <br/>
                                                       (+91) 8308706459 
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="wt-icon-box-wraper left p-b30">
                                                <div className="icon-sm"><i className="iconmoon-email" /></div>
                                                <div className="icon-content text-white">
                                                    <h5 className="m-t0  text-uppercase">Email address</h5>
                                                    <p>underthearchassosiates@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="wt-icon-box-wraper left">
                                                <div className="icon-sm"><i className="iconmoon-travel" /></div>
                                                <div className="icon-content text-white">
                                                    <h5 className="m-t0  text-uppercase">Address info</h5>
                                                    <p>OFFICE NO. 1 D, 2nd Floor, Cerebrum IT Park,Kalyani Nagar, Pune - 411014</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-full">
                    <div className="gmap-outline">

                          <div>
                            <iframe
                                title="Google Map"
                                width="100%"
                                height="460"
                                frameBorder="0"
                                style={{ border: 0 }}
                                src={embedUrl}
                                allowFullScreen
                            ></iframe>
                            </div>
                    </div>
                </div>
                {/* SECTION CONTENT END */}
            </div>

            <Footer />
        </>
    );
}

export default ContactUs;