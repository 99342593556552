import React,{ useEffect,useState }  from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { format } from 'date-fns';
import generateSlug from './../Utility/slugify';
import axios from 'axios';
import config  from '../Common/Config';


const loadScript = (src) => 
{
  return new Promise((resolve, reject) => 
  {
    const script = document.createElement('script');
    script.src = src;

    script.addEventListener('load', () => {
      document.body.removeChild(script); // Remove the script after it has loaded
      resolve();
    });

    script.addEventListener('error', (e) => {
      document.body.removeChild(script); // Remove the script if there's an error
      reject(e);
    });

    document.body.appendChild(script);
  });
};

const BlogSidebar = (props) =>
{
    useEffect(() => 
    {
        loadScript('./assets/js/custom.js');

        const params = 
        {
            blogid: props.blogid,
        };

        const apiUrl = config.apiBaseUrl + 'Get_Recent_Blogs.php';

        axios.get(apiUrl, {
            params: params,
        })
      .then(response => 
        {
            const blogData = response.data;

            const Status=blogData.Status;

            if(Status===1)
            {
                const blogdetailData=blogData.BlogMaster;
                setrecentblogmaster(blogdetailData);
            }
            else
            {
                console.error(blogData.Message);
            }
            setLoading(false); 
      })
      .catch(error => 
        {
            // Handle errors here
            console.error(error);
            setLoading(false); 
        });

       const apiUrl2 = config.apiBaseUrl + 'Get_Clients.php';

       axios.get(apiUrl2)
      .then(response => 
        {
            const clientData = response.data;

            const Status=clientData.Status;

            if(Status===1)
            {
                const clientsData=clientData.ClientMaster;
                setclientmaster(clientsData);
            }
            else
            {
                console.error(clientData.Message);
            }
            setLoading(false); 
      })
      .catch(error => 
        {
            // Handle errors here
            console.error(error);
            setLoading(false); 
        });

      }, []);

    const baseUrl2 = config.imagePath + 'Client/';

    const [recentblogmaster, setrecentblogmaster] = useState([]);
    const [clientmaster, setclientmaster] = useState([]);
    const [loading, setLoading] = useState(true);

    const options = {
        loop: true,
        autoplay: true,
        items: 1,
        nav: false,
        dots: true,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    };
    return (
        <>
            <link rel="stylesheet" type="text/css" href="./assets/css/style.css" />
            <div className="col-md-4">
                <aside className="side-bar">
                   
                    <div className="widget bg-white">
                    <h4 class="widget-title text-uppercase">About Blog</h4>
                        <div className="widget-post m-b15">
                            <img src={props.blogimage} alt="" className="img-responsive" />
                        </div>
                        <p>
                            {props.short_description}
                        </p>
                    </div>
                    <div className="wt-divider bg-black"><i className="icon-dot c-square" /></div>
                    {/* RECENT POSTS */}
                    <div className="widget bg-white  recent-posts-entry">
                        <h4 className="widget-title  text-uppercase">Recent Posts</h4>
                        <div className="section-content">
                            
                            <div className="widget-post-bx">
                                {recentblogmaster.map((item, index) => 
                                {
                                     const validDate2 = new Date(item.creationdate);
                                     const formattedDate2 = format(validDate2, 'dd MMM yyyy');

                                     const blogslug2 = generateSlug(item.title);
                                     const blogdetailpageurl2 = "/blog/"+blogslug2 +'/'+item.id;

                                     const recent_blog_image = config.imagePath + 'Blog/' + item.main_image_url;

                                    return (
                                    <div key={index} className="widget-post clearfix">
                            
                                        <div className="wt-post-media">

                                              <NavLink to={blogdetailpageurl2}>
                                                <img   className="recentblogimg" src={recent_blog_image} alt="" />
                                              </NavLink>
                                        </div>
                                      
                                        <div className="wt-post-info">
                                            <div className="wt-post-header">
                                             <NavLink to={blogdetailpageurl2}>
                                                <h6 className="post-title">{item.title}</h6>
                                                 </NavLink>
                                            </div>
                                            <div className="wt-post-meta">
                                                <ul>
                                                    <li className="post-author">{formattedDate2}</li>
                                                    {/* <li className="post-comment"> {item.comment}</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                )})}
                            </div>
                        </div>
                    </div>
                    <div className="wt-divider bg-black"><i className="icon-dot c-square" /></div>
                    {/* OUR CLIENT */}
                    <div className="widget">
                        <h4 className="widget-title  text-uppercase">Our Client</h4>
                        <OwlCarousel className="owl-carousel widget-client p-t10" {...options}>
                            {clientmaster.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="ow-client-logo">
                                        <div className="client-logo wt-img-effect on-color">
                                       <img src={baseUrl2 + item.imageurl} alt="" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </OwlCarousel>
                        
                    </div>
                    
                </aside>
            </div>
        </>
    );
}

export default BlogSidebar;