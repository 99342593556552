import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/banner/about-banner.webp');

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="Creating spaces that reflect your style." pagename="Privacy policy" bgimage={bnrimg.default}/>
                    <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                   
                                    <div className="col-lg-12 col-md-12">
                                        <div className="m-about-containt text-uppercase text-black p-t30">
                                            <span className="font-30 font-weight-300">Privacy Policy</span>
                                            
                                            <p className="text-initial m-t10">
                                            At Under The Arch, we respect your right to privacy and are dedicated to keeping your personal data safe. By interacting with our website, utilizing our services, or communicating with us, we will ensure the collection, usage, disclosure, and protection of your personal information in accordance with the guidelines outlined in this Privacy Policy.
                                            </p>

                                            <p><b>1. Information We Collect</b></p>
                                          
                                            <p className="text-initial">We have the ability to gather a variety of personal information, which may include, but is not limited to: <br/>
                                            Contact Information: When you contact us, inquire about our services, or request information, kindly provide your name, email address, phone number, and postal address. This will help us serve you better and ensure that we can easily reach out to you. Thank you for your cooperation.<br/>
                                            We value your privacy and will ensure that your personal details are handled securely and with the utmost care.<br/>
                                            Data Collection: We have the ability to gather information regarding your usage of our website and services, including your IP address, browser type, pages visited, and other patterns of usage.<br/>
                                            Cookies and Related Technologies: To learn more about your browsing habits and preferences, we may use cookies and related technologies. Through your browser's settings, you can limit how often cookies are used.
                                            </p>

                                            <p><b>2. The Use of Your Information by Us,</b></p>

                                            <p className="text-initial">
                                            We utilize the data we collect for a multitude of purposes, including:<br/>

                                            Delivering Services: To communicate with you, provide our architectural services, and carry out your demands.<br/>
                                            Website Improvement: Enhancing and optimizing your experience on our website, examining usage trends, and enhancing our offerings in terms of both content and services.<br/>
                                            Communication: To answer your questions, give you updates, and send you pertinent details about our services.<br/>
                                            Marketing: With your permission, we might send you information about our services or promotional materials.
                                            </p>

                                            <p><b> 3. Information Sharing</b></p>

                                            <p className="text-initial">Your personal information is not traded, sold, or rented to outside parties by us. We might disclose your information in the following situations:<br/>
                                            Service Providers: In order for us to deliver our services, we may share your information with outside service providers.<br/>
                                            Legal Requirements: When compelled by law, to abide by court orders, or to preserve our rights, we may share personal information.</p>

                                            <p><b> 4. Data Security</b></p>

                                            <p className="text-initial">To protect your personal information against loss, theft, misuse, and unauthorised access, disclosure, alteration, or destruction, we use the necessary safeguards. We cannot, however, make this assurance because no data transmission over the Internet can be 100% guaranteed to be secure.</p>
                                            
                                            <p><b> 5. Your Choices</b></p>

                                            <p className="text-initial">You have the right to:<br/>
                                            <ul class="list-circle font-12">
                                                <li>Access and update your personal data.</li>
                                                <li>Refuse to give your permission for marketing communications.</li>
                                                <li>Request the deletion of your personal data, subject to any applicable laws.</li>
                                            </ul>
                                            </p>

                                            <p><b> 6. Changes to This Privacy Policy</b></p>

                                            <p className="text-initial">To reflect changes in our procedures or for other operational, legal, or regulatory reasons, we may amend this privacy policy from time to time. Any significant changes will be communicated to you via our website or other modes of contact.</p>


                                            <p><b>7. Contact Us</b></p>
                                            <p className="text-initial">Please email us at info@underthearch_associates.com if you have any inquiries, requests, or concerns about your personal information or this privacy statement. <br/>
                                            You agree to this Privacy Policy's terms by using our website and services. Please check this policy frequently for any updates or modifications.</p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    };
};

export default PrivacyPolicy;