import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const LandingHeader = () => {
  const [logo, setLogo] = useState(
    require("./../../images/logo-dark-horizontal.webp")
  );
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const handleSearchToggle = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleMenuToggle = () => {
    setIsMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      setLogo(logopath);
    };

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    {/* Header Start  */}
      <header
        className={`${
          isMenuActive ? "active" : null
        } site-header header-style-1  nav-wide mobile-responsive-navigation bg-black`} >
        <div className="sticky-header main-bar-wraper">
          <div className="main-bar landingheader p-t10">
            <div className="container">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={"/"}>
                    <img
                      src={logo.default}
                      width="auto"
                      height={49}
                      alt="Under The Arch"
                    />
                  </NavLink>
                </div>
              </div>
              <div className="header-nav navbar-collapse collapse navsidebtn ">
                <span className="btnuppertag">
                  Book an appointment for consultation
                </span>
                <br />
                <button type="button" className="btn btn-sm btn quotebtn" data-toggle="modal" data-target="#exampleModalCenter">
                  {" "}
                  GET FREE QUOTE{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Header End */}
    </>
  );
};

export default LandingHeader;
