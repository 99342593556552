import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/banner/about-banner.webp');

class TermNCondition extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="Creating spaces that reflect your style." pagename="Term and condition" bgimage={bnrimg.default}/>
                    <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="m-about-containt text-uppercase text-black p-t30">
                                            <span className="font-30 font-weight-300 m-b10">Term and Conditions of Use for Under The Arch</span>
                                            {/* <h2 className="font-40">Term and Conditions of Use for Under The Arch</h2> */}
                                            <p className="text-initial m-t10">Welcome to the official webpage for "Under The Arch." You need to agree with these terms and conditions of use to access and use our website (the "Terms"). You consent to agreeing with and being obligated by these Terms by accessing and using this website. Use of our website is prohibited if you disagree with these Terms. 
                                            </p>

                                            <p><b>1. Acceptance of Terms</b></p>

                                            <p className="text-initial">1.1. By choosing to access and utilize the captivating platform known as "Under The Arch," you willingly commit to comply with and uphold these incredibly empowering Terms and Conditions of Use. To access the website, you must be a minimum of 18 years old and pledge to use it solely for lawful purposes following all applicable laws and regulations.</p>

                                            <p><b>2. Intellectual Property</b></p>

                                            <p className="text-initial">2.1. The Website contains a wide array of captivating content, such as mesmerizing text, stunning graphics, sleek logos, captivating images, and innovative software. All of this remarkable content belongs to the esteemed ownership of Under The Arch and is safeguarded by copyright and other intellectual property laws. You may not reproduce, distribute, modify, or display any content from the Website without prior written consent from Under The Arch.</p>
                                            
                                            <p className="text-initial">2.2. A restricted license enables you to use the Website for non-commercial, personal purposes. This license does not provide the following rights:
                                                Utilize robots, data mining, or other tools to collect or extract data.
                                                To enclose any brand, logo, or other proprietary material, frame it or use framing techniques.
                                                Use Under The Arch's name or trademarks in any meta tags or other hidden language.
                                            </p>

                                            <p><b>3. Privacy</b></p>

                                            <p className="text-initial">3.1. Your privacy is something that we take very seriously. You must fully comply with these Terms & Conditions and our Privacy Policy, which dictate the proper use of all personal information collected through this Website.</p>
                                            
                                            <p><b>4. Information Accuracy</b></p>

                                            <p className="text-initial">4.1. While we strive for accuracy, the information provided on the Website is for general informational purposes only and does not constitute professional advice. Under the Arch does not warrant the information's accuracy, completeness, or reliability.</p>
                                            
                                            <p className="text-initial">4.2. By using the Website, you understand and accept that you assume the responsibility for any reliance on the information provided. Under The Arch is not responsible for any inaccuracies, mistakes, or omissions in the information provided on the Website.</p>
                                     
                                            <p><b>5. User Conduct</b></p>

                                            <p className="text-initial">5.1. You agree to use the Website in a manner consistent with all applicable laws and regulations. You may not: 
                                            

                                            <br/>
                                            <br/>

                                            <ul class="list-circle font-12">
                                                <li>Do not upload, post, or transmit any content that violates the law or causes harm. This includes content that is threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invades someone's privacy, promotes hate, or is racially, ethnically, or otherwise objectionable.</li>
                                                <li>Falsely claim or otherwise misrepresent your association with a person or entity, or impersonate any person or entity.</li>
                                                <li>Do not upload, post, or transmit any content for which you do not have the legal right to do so. This includes content that is protected by laws or that is subject to contractual or fiduciary relationships, such as confidential information that you learned or disclosed during employment or under non-disclosure agreements.</li>
                                                <li>Any content that violates a third party's patent, trademark, trade secret, copyright, or other intellectual rights may not be uploaded, posted, or transmitted.</li>
                                                <li>Any unwelcome or unauthorized advertising, solicitation, "junk mail," "spam," "chain letters," "pyramid schemes," or other items should not be uploaded, posted, or sent.</li>
                                            </ul>
                                            </p>

                                            <p><b>6. Liability Limitations</b></p>

                                            <p className="text-initial">6.1. Under no circumstances, including negligence, will ‘Under The Arch’ or any of its affiliates be held liable for any damages, both direct and indirect, that may arise from or are associated with the use of its services. the Website. Damages for loss of Revenue, use, data, or any other intangible losses are included here, but they are not the only ones. You can be confident that we cannot be held accountable even if we were informed of the possibility of such damages.</p>
                                            
                                            <p className="text-initial">6.2. Please note that the limitation of responsibility mentioned may not apply to you, as some countries do not allow for the exclusion or limitation of liability for consequential or incidental damages.</p>
                                            
                                            <p><b>7. Governing Law</b></p>

                                            <p className="text-initial">7.1. These Terms and Conditions are governed by and construed by the laws of the Indian Government. Any legal action or proceeding relating to your access to or use of the Website shall be instituted in [Your Jurisdiction]. By accepting and agreeing to this, both you and Under The Arch consent to be bound by the authority of these courts and acknowledge that they are the appropriate venue for any legal actions or proceedings.</p>
                                            
                                            <p><b>8. Changes to Terms and Conditions</b></p>
                                            
                                            <p  className="text-initial">8.1. Under The Arch retains the right, with or without previous notice, to change or update these Terms and Conditions at any moment. If you use the Website after any such modifications, you are considered to have accepted the new terms.</p>
                                     

                                            <p><b>9. Contact details</b></p>

                                            <p  className="text-initial">9.1 Please get in touch with us at info@underthearch_associates.com
                                            If you have any queries or complaints about these terms and conditions.

                                            Please be aware that this thorough version includes a lengthy introduction and lists the guidelines for using the website "Under The Arch." To ensure compliance with certain governmental requirements and industry norms relevant to your architecture firm and jurisdiction, it is advisable to get legal advice.
                                            </p>



                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    };
};

export default TermNCondition;