import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import generateSlug from './../Utility/slugify';
import config from '../Common/Config';
import '../Segments/projectdropdown.css';

const filters = [
  { label: "Architecture Building", filter: ".11", catid: "1" },
  { label: "Commercial Interior", filter: ".12", catid: "2" },
  { label: "Residential Interior", filter: ".13", catid: "3" },
];

function LatestProjectsNew() {
  const [projects, setProjectsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subcatdata, setSubcatData] = useState([]);

  useEffect(() => {
    const apiUrlProjects = config.apiBaseUrl + 'Get_Projects.php';
    const paramsProjects = {
      pagetype: 'Home',
    };

    axios.get(apiUrlProjects, { params: paramsProjects })
      .then((response) => {
        const projectMasterData = response.data.ProjectMaster;
        setProjectsData(projectMasterData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
        setLoading(false);
      });

    const apiUrlSubcategories = config.apiBaseUrl + 'Get_SubCategory.php';

    // Fetch subcategories for all category IDs
    const subcategoryPromises = filters.map(async (item) => {
      const paramsSubcategories = {
        categoryid: item.catid,
      };

      try {
        const subcatresponse = await axios.get(apiUrlSubcategories, { params: paramsSubcategories });
        const subdatget = subcatresponse.data.SubcatMaster;
        return subdatget;
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        return [];
      }
    });

    // Wait for all subcategory requests to complete
    Promise.all(subcategoryPromises)
      .then((subcategories) => {
        // Update subcategories state
        setSubcatData(subcategories);
      });

    const loadScript = (src) => {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    };

    loadScript('./assets/js/custom.js');
  }, []);

  const options = {
    loop: false,
    autoplay: true,
    margin: 20,
    nav: true,
    dots: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    responsive: {
      0: { items: 1 },
      480: { items: 1 },
      580: { items: 2 },
      767: { items: 2 },
      991: { items: 3 },
      1152: { items: 4 },
      1360: { items: 4 },
      1366: { items: 5 }
    }
  };

  return (
    <>
      <div className="section-full p-t90 p-lr80 latest_project-outer square_shape3">
        <div className="section-head text-left">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <h2 className="text-uppercase font-36">Latest Project</h2>
              <div className="wt-separator-outer">
                <div className="wt-separator bg-black" />
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <ul className="btn-filter-wrap nav navbar-nav">
                <li className="btn-filter1 btn-active" data-filter="*">
                  All Project
                </li>
                {filters.map((item, index) => (
                  <li key={index} className="menu-item dropdownbtn-filter has-child">
                    {item.label}
                    {subcatdata[index] && (
                      <ul className="sub-menu v-sub-menu">
                        {subcatdata[index].map((getdata, subIndex) => {
                          var filtername = '.' + getdata.categoryid + getdata.id;
                          return (
                            <li key={subIndex}
                              className="btn-filter contentdropdown"
                              data-filter={filtername}>
                              {getdata.title}  
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="section-content ">
          <OwlCarousel className="owl-carousel owl-carousel-filter  owl-btn-bottom-left" {...options}>
            {projects.map((item, index) => {
              const projectslug = generateSlug(item.name);
              const projectdetailpageurl = "/project/" + projectslug + '/' + item.id;
              const projectimage = config.imagePath + 'Project/' + item.main_image_url;
              var classNameproject = item.categoryid + item.subcategoryid;
              return (
                <div key={index} className={`${classNameproject} item fadingcol`}>
                  <div className="wt-img-effect ">
                    <img src={projectimage} alt="" />
                    <div className="overlay-bx-2 ">
                      <div className="line-amiation">
                        <div className="text-white  font-weight-300 p-a40">
                          <h2><NavLink to={projectdetailpageurl} className="text-white font-20 letter-spacing-1 text-uppercase">{item.name}</NavLink></h2>
                          <p>{item.title}</p>
                          <NavLink to={projectdetailpageurl} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
        </div>
        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
          <strong>Awesome</strong>
          <span className="text-black">Designs</span>
        </div>
      </div>
    </>
  );
}

export default LatestProjectsNew;

// import React, { useEffect, useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import axios from 'axios';
// import generateSlug from './../Utility/slugify';
// import config from '../Common/Config';
// import '../Segments/projectdropdown.css';

// const filters = [
//   { label: "Arctitecture Building", filter: ".11", catid: "1" },
//   { label: "Commercial Interior", filter: ".12", catid: "2" },
//   { label: "Residential Interior", filter: ".13", catid: "3" }
// ];

// function LatestProjectsNew() {
//   const [projects, setProjectsData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [subcatdata, setSubcatData] = useState([]);

//   useEffect(() => {
//     const apiUrlProjects = config.apiBaseUrl + 'Get_Projects.php';
//     const paramsProjects = {
//       pagetype: 'Home',
//     };

//     axios.get(apiUrlProjects, { params: paramsProjects })
//       .then((response) => {
//         const projectMasterData = response.data.ProjectMaster;
//         setProjectsData(projectMasterData);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error('Error fetching projects:', error);
//         setLoading(false);
//       });

//     const apiUrlSubcategories = config.apiBaseUrl + 'Get_SubCategory.php';

//     // Fetch subcategories for all category IDs
//     const subcategoryPromises = filters.map(async (item) => {
//       const paramsSubcategories = {
//         categoryid: item.catid,
//       };

//       try {
//         const subcatresponse = await axios.get(apiUrlSubcategories, { params: paramsSubcategories });
//         const subdatget = subcatresponse.data.SubcatMaster;
//         return subdatget;
//       } catch (error) {
//         console.error('Error fetching subcategories:', error);
//         return [];
//       }
//     });

//     // Wait for all subcategory requests to complete
//     Promise.all(subcategoryPromises)
//       .then((subcategories) => {
//         // Update subcategories state
//         setSubcatData(subcategories);
//       });

//     const loadScript = (src) => {
//       return new Promise(function (resolve, reject) {
//         var script = document.createElement('script');
//         script.src = src;
//         script.addEventListener('load', function () {
//           resolve();
//         });
//         script.addEventListener('error', function (e) {
//           reject(e);
//         });
//         document.body.appendChild(script);
//         document.body.removeChild(script);
//       });
//     };

//     loadScript('./assets/js/custom.js');
//   }, []);

//   const options = {
//     loop:false,
//     autoplay:true,
//     margin:20,
//     nav:true,
//     dots: false,
//     navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
//     responsive:{
//         0:{
//             items:1
//         },
//         480:{
//             items:1
//         },
//         580:{
//             items:2
//         },						
//         767:{
//             items:2
//         },
//         991:{
//             items:3
//         },			
//         1152:{
//             items:4
//         },
//         1360:{
//             items:4
//         },
//         1366:{
//             items:5
//         }	
//         }
// };

// return (
//     <>
//       <div className="section-full p-t90 p-lr80 latest_project-outer square_shape3">
//         <div className="section-head text-left">
//           <div className="row">
//             <div className="col-lg-4 col-md-12 ">
//               <h2 className="text-uppercase font-36">Latest Project</h2>
//               <div className="wt-separator-outer">
//                 <div className="wt-separator bg-black" />
//               </div>
//             </div>
//             <div className="col-lg-8 col-md-12">
//               <ul className="btn-filter-wrap nav navbar-nav">
//                 <li className="btn-filter btn-active" data-filter="*">All Project</li>
//                 {filters.map((item, index) => (
//                   <li id="menu-products" key={index} >
//                     {item.label}
//                     {subcatdata[index] && subcatdata[index].map((getdata, subIndex) => 
//                     {
//                         var filtername=  '.'+getdata.categoryid + getdata.id;
//                         return(
//                       <ul  className="sub-menu v-sub-menu"  id="dropdown-products" key={subIndex} >
//                         <li  className="btn-filter" data-filter={filtername} >{getdata.title}</li>
//                       </ul>
//                     )})}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="section-content">
//           <OwlCarousel className="owl-carousel owl-carousel-filter  owl-btn-bottom-left" {...options}>
//             {projects.map((item, index) => {
//               const projectslug = generateSlug(item.name);
//               const projectdetailpageurl = "/project/" + projectslug + '/' + item.id;
//               const projectimage = config.imagePath + 'Project/' + item.main_image_url;

//               var classNameproject = item.categoryid + item.subcategoryid;
              
//               return (
//                 <div key={index} className={`${classNameproject} item fadingcol`}>
//                   <div className="wt-img-effect ">
//                     <img src={projectimage} alt="" />
//                     <div className="overlay-bx-2 ">
//                       <div className="line-amiation">
//                         <div className="text-white  font-weight-300 p-a40">
//                           <h2><NavLink to={projectdetailpageurl} className="text-white font-20 letter-spacing-1 text-uppercase">{item.name}</NavLink></h2>
//                           <p>{item.title}</p>
//                           <NavLink to={projectdetailpageurl} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )
//             })}
//           </OwlCarousel>
//         </div>
//         <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
//           <strong>Awesome</strong>
//           <span className="text-black">Designs</span>
//         </div>
//       </div>
//     </>
//   );
// }

// export default LatestProjectsNew;
