import React,{ useEffect,useState }  from 'react';
import { NavLink, } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import generateSlug from './../Utility/slugify';
import { format } from 'date-fns';
import axios from 'axios';
import config  from '../Common/Config';

var bnrimg = require('./../../images/banner/blog-banner.webp');

const loadScript = (src) => 
{
  return new Promise((resolve, reject) => 
  {
    const script = document.createElement('script');
    script.src = src;

    script.addEventListener('load', () => {
      document.body.removeChild(script); // Remove the script after it has loaded
      resolve();
    });

    script.addEventListener('error', (e) => {
      document.body.removeChild(script); // Remove the script if there's an error
      reject(e);
    });

    document.body.appendChild(script);
  });
};

function Blogs() 
{
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  loadScript('./assets/js/custom.js');

  useEffect(() => 
  {
    // Define an async function within useEffect
    async function fetchData() 
    {
        const params = 
        {
            pagetype: "Blogs",
        };

        const apiUrl = config.apiBaseUrl + 'Get_Blogs.php';

        axios.get(apiUrl, {
            params: params,
        })
      .then(response => 
        {
            const blogData = response.data;

            const Status=blogData.Status;

            if(Status===1)
            {
                const blogdetailData=blogData.BlogMaster;
                setData(blogdetailData);
            }
            else
            {
                console.error(blogData.Message);
            }
            setLoading(false); 
      })
      .catch(error => 
        {
            // Handle errors here
            console.error(error);
            setLoading(false); 
        });
  
    }

    // Call the async function to fetch data
    fetchData();
  }, []); // The empty dependency array ensures this effect runs once when the component mounts

  if (loading) 
  {
    return <div>Loading...</div>;
  }

  if (data) 
  {
    // Render your component based on the fetched data
    return (
      <div>
        <Header2 />
        <div className="page-content">
          <Banner
            title="Your home, your style."
            pagename="Blog"
            bgimage={bnrimg.default}
          />

            <div className="section-full p-t140 bg-repeat tm-blog-wrap">
                <div className="container">
                    <div className="section-content">
                    
                        <div className="row">

                                {data.map((item, index) => 
                                {
                                    const validDate = new Date(item.creationdate);
                                    const formattedDate = format(validDate, 'dd MMM yyyy');

                                    const blogslug = generateSlug(item.title);
                                    const blogdetailpageurl = "/blog/"+blogslug +'/'+item.id;

                                    const blog_main_image = config.imagePath + 'Blog/' + item.main_image_url;

                                    // const isMultipleOfThree = (index) % 3 === 0;

                                    return (
                                      <div className="col-md-6 col-sm-12">
                                      <NavLink to={blogdetailpageurl}>
                                      <div key={index}className="overlay-wraper bg-no-repeat bg-cover latest-blog-dark-outer p-a20 m-b30"  style={{
                                          backgroundImage: `url(${blog_main_image})`,
                                      }}>
                                          
                                          <div className="overlay-main bg-black opacity-04" />
                                      
                                          <div className="latest-blog-dark text-uppercase p-a20">
                                              <h3 className="m-a0"><span className="text-white">{item.title}</span></h3>
                                              <div className={`text-white v-date  font-weight-700`}>{formattedDate}</div>
                                          </div>

                                      </div>
                                      </NavLink>
                                      </div>
                                  )

                                      // if (!isMultipleOfThree) 
                                      //   {
                                               
                                      //   }
                                      //   else
                                      //   {
                                      //       return (
                                      //           <NavLink to={blogdetailpageurl}>
                                      //           <div  key={index} className="overlay-wraper bg-no-repeat bg-cover latest-blog-dark-outer2 m-b30" style={{ backgroundImage: `url(${baseUrl}${item.large_image_url})` }}>
                                      //               <div className="overlay-main bg-black opacity-04" />
                                      //               <div className="latest-blog-square text-white">
                                      //                   <h2 className="m-t0 m-b10"><span className="text-white font-30 text-uppercase">{item.title}</span></h2>
                                      //                   <p className="font-weight-300">{item.short_description}</p>
                                      //                   <NavLink to={blogdetailpageurl} className="letter-spacing-4 font-12 text-white text-uppercase">Read More</NavLink>
                                      //                   <div className={`text-white v-date  font-weight-700 text-uppercase`}>{formattedDate}</div>
                                      //               </div>
                                      //           </div>
                                      //           </NavLink>
                                      //       )
                                      //   }
                                })}
                                
                           
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                        <strong>Blog</strong>
                        <span className="text-black">Post</span>
                    </div>
                </div>
            </div>
        </div>
      <Footer />
    </div>
    );
  }

  return null; // You can also return null or some other fallback UI when data is not available
}

export default Blogs;
