import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios'; 
import config  from '../Common/Config';

function SliderNew() {
  const [slideData, setSlideData] = useState([]); 

  useEffect(() => {
  // Function to fetch slide data from the API
  async function fetchSlideData() 
  {
    try {
      const apiUrl = config.apiBaseUrl + 'Get_Slider.php';
      const response = await axios.get(apiUrl);

      const sliderdata=response.data;


      setSlideData(sliderdata.SliderMaster); // Set the fetched data to the state

      // Load scripts after fetching data
      await loadScripts();
    } catch (error) {
      console.error('Error fetching slide data:', error);
    }
  }

  fetchSlideData();
}, []);

// Function to load scripts
async function loadScripts() 
{
  try {
    await loadScript('./assets/js/rev-script-2.js');
    await loadScript('./assets/plugins/revolution-addons/beforeafter/js/revolution.addon.beforeafter.min.js');
  } catch (error) {
    console.error('Error loading scripts:', error);
  }
}

    // Function to load a script dynamically
  function loadScript(src) 
  {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  }

  return (
    <div id="rev_slider_346_1_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="beforeafterslider1" data-source="gallery" style={{ background: '#252525', padding: 0 }}>
      {/* START REVOLUTION SLIDER 5.4.3.3 fullscreen mode */}
      <div id="rev_slider_346_1" className="rev_slider fullscreenbanner" style={{ display: 'none' }} data-version="5.4.3.3">
        <ul>
          {slideData.map((item, index) => (

<li  key={index}  data-index={`rs-${964 + index}`} data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="{revslider-weather-addon : { type :  ,name :  ,woeid :  ,unit :  }}" data-description="" data-beforeafter={`{"moveto":"50%|50%|50%|50%","bgColor":"#e7e7e7","bgType":"image","bgImage":"${config.imagePath + 'Slider/' + item.imageurl}","bgFit":"cover","bgPos":"center center","bgRepeat":"no-repeat","direction":"horizontal","easing":"Power2.easeInOut","delay":"500","time":"750","out":"fade","carousel":false}`}>
{/* MAIN IMAGE */}
<img src={config.imagePath + 'Slider/' + item.black_image_url} data-beforeafter="after" data-bgcolor="" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina />
{/* LAYERS */}
{/* LAYER NR. 1 text */}
<div className="tp-caption   tp-resizeme rs-parallaxlevel-5" id={`slide-${964 + index}-layer-1`}
  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
  data-y="['middle','middle','middle','middle']" data-voffset="['110','110','110','0']" 
  data-fontsize="['120','120','120','60']"
  data-lineheight="['120','120','120','60']"
  data-letterspacing="['50','50','50','5']"
  data-width="['960','960','960','960']"
  
  data-height="none"
  data-whitespace="normal"

  data-type="text" 
  data-beforeafter="before" 
  data-responsive_offset="on" 

  data-frames='[{"delay":600,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
  data-textalign="['center','center','center','center']"
  data-paddingtop="[0,0,0,0]"
  data-paddingright="[0,0,0,50]"
  data-paddingbottom="[0,0,0,0]"
  data-paddingleft="[50,50,50,50]"

  style={{ zIndex: 16, whiteSpace: 'nowrap', fontSize: 120, lineHeight: 120, fontWeight: 700, color: '#000', letterSpacing: 50, fontFamily: 'Montserrat', textTransform: 'uppercase' }}>{item.title}</div>

<div className="tp-caption   tp-resizeme rs-parallaxlevel-5" 
  id={`slide-${964 + index}-layer-2`}
  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
  data-y="['middle','middle','middle','middle']" data-voffset="['210','210','210','100']" 
  data-width="['960','960','960','320']"
  data-height="none"
  data-whitespace="normal"

  data-type="text" 
  data-beforeafter="before" 
  data-responsive_offset="on" 

  data-frames='[{"delay":600,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
  data-textalign="['center','center','center','center']"
  data-paddingtop="[0,0,0,0]"
  data-paddingright="[0,0,0,0]"
  data-paddingbottom="[0,0,0,0]"
  data-paddingleft="[5,5,5,5]"
  data-fontsize="['20','20','20','20']"
  data-lineheight="['24','26','26','26']"

  style={{ zIndex: 11, minWidth: 960, maxWidth: 960, whiteSpace: 'normal', fontWeight: 700, color: '#000', letterSpacing: 5, fontFamily: 'Montserrat', textTransform: 'uppercase' }}>{item.subtitle}</div>

  <div className="tp-caption   tp-resizeme rs-parallaxlevel-4" 
  id={`slide-${964 + index}-layer-3`}
  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
  data-y="['bottom','bottom','bottom','bottom']" data-voffset="['70','70','70','80']" 
  data-lineheight="['60','60','60','60']"
  data-width="none"
  data-height="none"
  data-whitespace="nowrap"

  data-type="text" 
  data-beforeafter="before" 
  data-responsive_offset="on" 

  data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},
  {"frame":"hover","speed":"200","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;","style":"c:rgb(0,0,0);bg:rgb(0,0,0);"}]'
  
  data-textalign="['center','center','center','center']"
  data-paddingtop="[0,0,0,0]"
  data-paddingright="[40,40,40,40]"
  data-paddingbottom="[0,0,0,0]"
  data-paddingleft="[45,45,45,45]"

  style={{ zIndex: 12, whiteSpace: 'nowrap', fontSize: 15, lineHeight: 60, fontWeight: 700, color: '#fff', letterSpacing: 5, fontFamily: 'Montserrat', backgroundColor: 'rgb(0,0,0)', cursor: 'pointer' }}>
      
      <NavLink to={"/contactus"} title="GET FREE QUOTE" rel="bookmark" style={{color: '#fff'}}>GET FREE QUOTE</NavLink>
  </div>

  <div className="tp-caption   tp-resizeme  tp-blackshadow rs-parallaxlevel-5" 
  id={`slide-${964 + index}-layer-4`}
  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
  data-y="['middle','middle','middle','middle']" data-voffset="['110','110','110','0']" 
  data-fontsize="['120','120','120','60']"
  data-lineheight="['120','120','120','60']"
  data-letterspacing="['50','50','50','5']"
  data-width="['960','960','960','960']"
  data-height="none"
  data-whitespace="nowrap"
  

  data-type="text" 
  data-beforeafter="after" 
  data-responsive_offset="on" 

  data-frames='[{"delay":2000,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
  data-textalign="['center','center','center','center']"
  data-paddingtop="[0,0,0,0]"
  data-paddingright="[0,0,0,50]"
  data-paddingbottom="[0,0,0,0]"
  data-paddingleft="[50,50,50,50]"


  style={{ zIndex: 16, whiteSpace: 'nowrap', fontSize: 120, lineHeight: 120, fontWeight: 700, color: '#ffffff', letterSpacing: 50, fontFamily: 'Montserrat', textTransform: 'uppercase' }}>{item.title} </div>


  <div className="tp-caption   tp-resizeme rs-parallaxlevel-5" 
  id={`slide-${964 + index}-layer-5`}
  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
  data-y="['middle','middle','middle','middle']" data-voffset="['210','210','210','100']" 
  data-width="['960','960','960','320']"
  data-height="none"
  data-whitespace="normal"

  data-type="text" 
  data-beforeafter="after" 
  data-responsive_offset="on" 

  data-frames='[{"delay":2100,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
  data-textalign="['center','center','center','center']"
  data-paddingtop="[0,0,0,0]"
  data-paddingright="[0,0,0,0]"
  data-paddingbottom="[0,0,0,0]"
  data-paddingleft="[5,5,5,5]"
  data-fontsize="['20','20','20','20']"
  data-lineheight="['24','26','26','26']"

  style={{ zIndex: 17, minWidth: 960, maxWidth: 960, whiteSpace: 'normal', fontWeight: 700, color: '#ffffff', letterSpacing: 5, fontFamily: 'Montserrat', textTransform: 'uppercase' }}>{item.subtitle}</div>

  <div className="tp-caption   tp-resizeme rs-parallaxlevel-4" 
  id={`slide-${964 + index}-layer-6`} 
  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
  data-y="['bottom','bottom','bottom','bottom']" data-voffset="['70','70','70','80']" 
  data-lineheight="['60','60','60','60']"
  data-width="none"
  data-height="none"
  data-whitespace="nowrap"

  data-type="text" 
  data-beforeafter="after" 
  data-responsive_offset="on" 

  data-frames='[{"delay":2200,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},
  {"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},
  {"frame":"hover","speed":"200","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;","style":"c:rgb(0,0,0);bg:rgb(255,255,255);"}]'
  data-textalign="['center','center','center','center']"
  data-paddingtop="[0,0,0,0]"
  data-paddingright="[40,40,40,40]"
  data-paddingbottom="[0,0,0,0]"
  data-paddingleft="[45,45,45,45]"

  style={{ zIndex: 18, whiteSpace: 'nowrap', fontSize: 15,lineHeight:60, fontWeight: 700, color: '#000', letterSpacing: 5, fontFamily: 'Montserrat', backgroundColor: 'rgb(255,255,255)', cursor: 'pointer' }}>
      <NavLink to={"/contactus"} title="GET FREE QUOTE" rel="bookmark">GET FREE QUOTE </NavLink>
  </div>

</li>


          ))}
        </ul>
        <div
          className="tp-bannertimer tp-bottom"
          style={{ height: 5, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        ></div>
      </div>
    </div>
  );
}

export default SliderNew;
