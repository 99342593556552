import React from 'react';

const ShareOnWhatsApp = ({ caption, image, blogPostUrl }) => {
    const shareOnWhatsApp = () => {
        const encodedCaption = encodeURIComponent(caption);
        // const encodedImage = encodeURIComponent(image);
        const encodedUrl = encodeURIComponent(blogPostUrl);

        const shareText = `Check out this awesome blog post: ${encodedUrl} ${encodedCaption}`;
        const shareUrl = `https://api.whatsapp.com/send?text=${shareText}`;

        window.open(shareUrl, '_blank');
        // window.location.href = shareUrl;
    };

    return (
        <li><a href="javascript: void(0);" onClick={shareOnWhatsApp}  className="fa fa-whatsapp" /></li>
    );
};

export default ShareOnWhatsApp;
