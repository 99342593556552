import React, { useState, useEffect } from 'react';
import Navigation from '../Common/Navigation';
import { NavLink } from 'react-router-dom';

const Header2 = () => {
    const [logo, setLogo] = useState(require('./../../images/logo-dark-horizontal.webp'));
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isMenuActive, setIsMenuActive] = useState(false);

    const handleSearchToggle = () => {
        setIsSearchActive(!isSearchActive);
    };

    const handleMenuToggle = () => {
        setIsMenuActive(!isMenuActive);
    };

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            const stickyheader = document.querySelector('.sticky-header ');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');
            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        };

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
            setLogo(logopath);
        };

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header className={`${isMenuActive ? "active" : null} site-header header-style-1  nav-wide mobile-responsive-navigation`}>
                <div className="sticky-header main-bar-wraper">
                    <div className="main-bar bg-white p-t10">
                        <div className="container">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to={"/"}>
                                        <img src={logo.default} width="auto" height={49} alt="Under The Arch" />
                                    </NavLink>
                                </div>
                            </div>
                            <button onClick={handleMenuToggle} id="mobile-responsive-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggle collapsed">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            <div className="header-nav navbar-collapse collapse">
                                <Navigation />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header2;
