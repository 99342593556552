import React, { useState,useRef } from 'react';

import LandingHeader from "../Common/LandingPageHeader";
import Footer from "../Common/Footer";
import "../Pages/LandingPageCss.css";

import icon1 from "../../images/Clip path group.png";
import icon2 from "../../images/Group 12.png";
import icon3 from "../../images/Group.png";
import config  from '../Common/Config';
import axios from 'axios';

function LandingPage ()
{
  const mobileNumberRef = useRef(null);

  const handleCheckChange = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sendUpdates: !prevFormData.sendUpdates,
    }));
  };

  // before onclick Submit
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileno: '',
    propertyname: '',
    sendUpdates: false,
  });

const [errorMessage, setErrorMessage] = useState('');
const [successMessage, setSuccessMessage] = useState('');

const handleChange= (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => 
  {
    e.preventDefault();

    const mobileNo = formData.mobileno;
    if (!isValidMobileNumber(mobileNo)) 
    {
      setErrorMessage('Mobile number must be a 10-digit number.');
      mobileNumberRef.current.focus();
      return;
    }

    try 
    {
      const apiUrl = config.apiBaseUrl + 'LandingPage_Enquiry.php';

      const configparameter = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const response = await axios.post(apiUrl, formData, configparameter);
          
    //   console.log('API response:', response.data);

      const Status=response.data.Status;

      if(Status===1)
      {
        setFormData({
            name: '',
            email: '',
            mobileno: '',
            propertyname: '',
          });

          setSuccessMessage("success");
          setTimeout(() => {
            setSuccessMessage('');
          }, 5000);
      }
      else if(Status===2)
      {
        setErrorMessage("Mobile number is already use for enquiry.");
      }
      else if(Status===0)
      {
        setErrorMessage("Message is not submited try again.");

        setTimeout(() => {
            setErrorMessage('');
          }, 5000);
      }

      // Optionally, you can reset the form after successful submission
      

    } catch (error) 
    {
      setErrorMessage("error");
      console.error('API error:', error);
    }
  };

  // Function to validate mobile number as 10 digits
    function isValidMobileNumber(mobileNo) 
    {
        // Remove non-digit characters
        const cleanedNumber = mobileNo.replace(/\D/g, '');
        return cleanedNumber.length === 10;
    }



return(
<>
  <LandingHeader />

  {/* Model Form Section Start */}
  <div className="page-content">
    <div className="wt-bnr-inr overlay-wraper  bg-top-center landingpageContent" data-stellar-background-ratio="0.5">
      <div className="overlay-main bg-black2 opacity-07"></div>
      <div className="row hedercontaint  parent">
        
        <div className="col-md-6">
          <h1 className="bannertext ">
            Transforming Spaces Into Innovative Architectural Solutions
          </h1>
        </div>
        <div className="col-md-6 modelform">
          <div className="center-container">
            <form className="center-form"  onSubmit={handleSubmit} >
              <div className="model-header text-center">
                <h3>Designs for Every Budget</h3>
              </div>
              <input  name="name" type="text" className="form-control" placeholder="Name" value={formData.name} onChange={handleChange} required />
              <br />
              <input name="email" type="email" className="form-control"  placeholder="Email" value={formData.email} onChange={handleChange} required />
              <br />
              <input name="mobileno" type="tel"    pattern="[6789][0-9]{9}" className="form-control"  placeholder="Mobile No" minlength="10" maxlength="10"  value={formData.mobileno} onChange={handleChange}  ref={mobileNumberRef}  required />
              <input type="checkbox" id="sendmewp" checked={formData.sendUpdates} onChange={handleCheckChange} />
              <label for="sendmewp">Send me updates on WhatsApp</label>
              <br />
              <input type="text" name="propertyname" className="form-control" placeholder="Property name" value={formData.propertyname} onChange={handleChange} required />
              <br />                      
              <button type="submit" value="Submit" className="btn btn-sm btn quotebtn2">
                GET FREE QUOTE
              </button>
              <div className="model-footer">
                <span>By submitting this form, you agree to the <span className="formpolicy">privacy policy</span> &
                  <span className="formpolicy">terms and conditions</span></span>
              </div>
              {errorMessage && <div className="alert alert-danger fade in  m-t10">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                {errorMessage}
            </div>}
        {successMessage && <div className="alert alert-success fade in m-t10">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                <strong>Thank you </strong> our team contact you shortly!
            </div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Model Form Section End */}
  <div className="overlay-wraper section21">
    <div className="overlay-main bg-black" style={{ opacity: 0 }} />
    <div className="container p-t30">
      <div className="row some-facts-counter">
        <div className="col-md-4  col-sm-4 col-xs-4 servicediv">
          <div className="wt-icon-box-wraper p-a10 text-white m-b30">
            <div className="icon-content text-center">
              <div className="font-40 font-weight-600 m-b5">
                <img src={icon1} alt="iconi1" className="icons mbicons1 mbicons2" style={{ height: "77px" }} />
              </div>
              <div className="wt-separator-outer m-b20">
                {/* <div className="wt-separator bg-white"></div> */}
              </div>
              <span className="text-uppercase icondetail">
                VERSATILE | DEISGN
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4  col-sm-4 col-xs-4 servicediv">
          <div className="wt-icon-box-wraper p-a10 text-white m-b30">
            <div className="icon-content text-center">
              <div className="font-40 font-weight-600 m-b5">
                <img src={icon2} alt="iconi1" className="icons mbicons1" />
              </div>
              <div className="wt-separator-outer m-b20">
                {/* <div className="wt-separator bg-white"></div> */}
              </div>
              <span className="text-uppercase icondetail">
                QUALITY | EXECUTION
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4  col-sm-4 col-xs-4 servicediv">
          <div className="wt-icon-box-wraper p-a10 text-white m-b30">
            <div className="icon-content text-center">
              <div className="font-40 font-weight-600 m-b5">
                <img src={icon3} alt="iconi1" className="icons mbicons1" />
              </div>
              <div className="wt-separator-outer m-b20">
                {/* <div className="wt-separator bg-white"></div> */}
              </div>
              <span className="text-uppercase icondetail">
                FOCUS | SERVICE
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Section 3 Start*/}
  <div className="footer-bottom overlay-wraper section3">
    <div className="container text-center">
      <div className="section-head  text-black">
        <h2 className="text-uppercase font-34">
          The design solution you desire{" "}
        </h2>
      </div>
      <div className="section-content ">
        <button type="button" className="btn  btn quotebtn3 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
          Book Free Consultation
        </button>
      </div>
      <div className="section-content sec3detail">
        <p className="sec3detail">
          When you give your space, Under the Arch touch, you get both
          beauty and functionality. We employ state-of-the-art technology
          to ensure your home features a flawless look that lasts a very
          long time.
        </p>
      </div>
    </div>
  </div>

  {/* Section 4 Start */}
  <div className="footer-bottom overlay-wraper section2">
    <div className="container text-center">
      <div className="section-head  text-black">
        <h2 className="text-uppercase font-34 sec3heading">
          WHAT WE OFFER ?
        </h2>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-4 mt-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-uppercase">ARCHITECTURE</h5>
              <p className="card-text">
                {" "}
                Our skilled architects translate your vision into
                blueprints that capture both aesthetics and functionality.
              </p>
              <button className="text-uppercase btn btn-outline-dark btn-square-md cardbtn"  >
                {" "}
                LEARN MORE
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-uppercase">
                INTERIOR DESIGN
              </h5>
              <p className="card-text">
                Our interior designers orchestrate the symphony of space,
                light, and texture, crafting ambiences that are both
                stylish and soulful.
              </p>
              <button className="text-uppercase btn btn-outline-dark btn-square-md cardbtn" >
                {" "}
                LEARN MORE
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-uppercase">
                PROJECT MANAGEMENT
              </h5>
              <p className="card-text">
                From budgeting and scheduling to vendor coordination and
                quality control, orchestrate the entire process, with
                exceeding expectations.
              </p>
              <button className="text-uppercase btn btn-outline-dark btn-square-md cardbtn" >
                {" "}
                LEARN MORE
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body ">
              <h5 className="card-title text-uppercase">LANDSCAPING</h5>
              <p className="card-text">
                Transforming your outdoors into an oasis of serenity or a
                vibrant hub of activity, tailored to your lifestyle.
              </p>
              <button className="text-uppercase btn btn-outline-dark btn-square-md cardbtn" >
                {" "}
                LEARN MORE
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-uppercase">SANCTIONING</h5>
              <p className="card-text">
                We handle the legwork with expertise, ensuring your
                project sails smoothly through the regulatory process.
              </p>
              <button className="text-uppercase btn btn-outline-dark btn-square-md cardbtn" >
                {" "}
                LEARN MORE
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-uppercase">3D MODEL</h5>
              <p className="card-text">
                Using cutting edge technology, we create realistic 3D
                models that bring your architectural deign to life.
              </p>
              <button className="text-uppercase btn btn-outline-dark btn-square-md cardbtn" >
                {" "}
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Model from start*/}
    <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="">
        <form className="center-form"  onSubmit={handleSubmit} >
              <div className="model-header text-center">
                <h3>Designs for Every Budget</h3>
              </div>
              <input  name="name" type="text" className="form-control" placeholder="Name" value={formData.name} onChange={handleChange} required />
              <br />
              <input name="email" type="email" className="form-control"  placeholder="Email" value={formData.email} onChange={handleChange} required />
              <br />
              <input name="mobileno" type="tel"    pattern="[6789][0-9]{9}" className="form-control"  placeholder="Mobile No" minlength="10" maxlength="10"  value={formData.mobileno} onChange={handleChange}  ref={mobileNumberRef}  required />
              <input type="checkbox" id="sendmewp" checked={formData.sendUpdates} onChange={handleCheckChange} />
              <label for="sendmewp">Send me updates on WhatsApp</label>
              <br />
              <input type="text" name="propertyname" className="form-control" placeholder="Property name" value={formData.propertyname} onChange={handleChange} required />
              <br />                      
              <button type="submit" value="Submit" className="btn btn-sm btn quotebtn2">
                GET FREE QUOTE
              </button>
              <div className="model-footer">
                <span>By submitting this form, you agree to the <span className="formpolicy">privacy policy</span> &
                  <span className="formpolicy">terms and conditions</span></span>
              </div>
              {errorMessage && <div className="alert alert-danger fade in  m-t10">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                {errorMessage}
            </div>}
        {successMessage && <div className="alert alert-success fade in m-t10">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">×</a>
                <strong>Thank you </strong> our team contact you shortly!
            </div>}
            </form>
        </div>
      </div>
    </div>
    {/* Model form End */}
  </div>
  
  {/* footer section */}
  <div className="footer-bottom overlay-wraper section2">
    <div className="container text-center">
      <button type="button" className="btn  btn quotebtn3 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
        GET FREE QUOTE
      </button>
    </div>
  </div>
  {/*
  <Footer /> */}
</>
);
}
export default LandingPage;