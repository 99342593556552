import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import SliderNew from './../Segments/SliderNew';
import About from './../Segments/About';
import LatestProjectsNew from './../Segments/LatestProjectsNew';
import WhoWeAre from './../Segments/WhoWeAre';
import BlogNew from './../Segments/BlogNew';
import Statistics from './../Segments/Statistics';
import Testimonials from './../Segments/Testimonials';
import ClientsLogoNew from './../Segments/ClientsLogoNew';

class Home3 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <SliderNew />
                    <About />
                    <LatestProjectsNew />
                    <WhoWeAre />
                    {/* <TeamMembers /> */}
                    {/* <BlogNew colorclass="text-white" /> */}
                    <Statistics />
                    {/* <Testimonials /> */}
                    <ClientsLogoNew />
                </div>

                <Footer />
            </>
        );
    };
};

export default Home3;