// slugify.js
import slugify from 'slugify';

// Create a function to generate slugs
const generateSlug = (productName) => {
  try {
    const slug = slugify(productName, {
      replacement: '-',  // Replace spaces with dashes
      lower: true,      // Convert to lowercase
      remove: /[*+~.()'"!:@]/g,  // Remove special characters
    });
    return slug;
  } catch (error) {
    console.error(`Error generating slug for "${productName}":`, error);
    // You can return a fallback slug or handle the error in some other way
    return 'error-slug';
  }
};

export default generateSlug;
