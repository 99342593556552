// ShareViaEmail.js
import React from 'react';

const ShareViaEmail = ({ subject, body, email }) => {
    const shareViaEmail = () => 
    {
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);
        const encodedEmail = encodeURIComponent(email);

        const emailLink = `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`;
        
        window.location.href = emailLink;
    };

    return (
        <li><a href="javascript: void(0);" onClick={shareViaEmail}  className="fa fa-envelope" /></li>
    );
};

export default ShareViaEmail;
