// ShareOnFacebook.js
import React from 'react';

const ShareOnFacebook = ({ title, blogUrl }) => {
    const shareOnFacebook = () => {
        const encodedTitle = encodeURIComponent(title);
        const encodedUrl = encodeURIComponent(blogUrl);

        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedTitle}`;
        
        window.open(shareUrl, '_blank');
    };

    return (
        <li><a href="javascript: void(0);" onClick={shareOnFacebook}  className="fa fa-facebook" /></li>
    );
};

export default ShareOnFacebook;
