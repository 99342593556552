import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home3 from './Pages/Home3';

import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';

import News from './Pages/News';
import NewsDetail from './Pages/NewsDetail';

import Projects from './Pages/Projects';
import ProjectDetail from './Pages/ProjectDetail';

import Blogs from './Pages/Blogs';
import BlogDetail from './Pages/BlogDetail';

import TermNCondition from './Pages/TermNCondition';
import PrivacyPolicy from './Pages/Privacy-Policy';

import LandingPage from './Pages/Landing-page';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                        <Switch>
                            <Route path='/' exact component={Home3} />
                
                            <Route path='/aboutus' exact component={AboutUs} />
                            <Route path='/contactus' exact component={ContactUs} />

                            <Route path='/news' exact component={News} />
                            <Route path="/news/:slug/:newsId" component={NewsDetail} />

    
                            <Route path='/projects' exact component={Projects} />

                            <Route path="/project/:slug/:projectId" component={ProjectDetail} />

                            <Route path='/blogs' exact component={Blogs} />

                            <Route path="/blog/:slug/:blogId"  component={BlogDetail} />

                            <Route path='/term-and-condition' exact component={TermNCondition} />

                            <Route path='/privacypolicy' exact component={PrivacyPolicy} />

                            <Route path='/landing-page' exact component={LandingPage} />
                            
                            <Route component={Error} /> 
                            <Route component={Error} />
                        </Switch>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;