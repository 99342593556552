// ShareOnTwitter.js
import React from 'react';

const ShareOnTwitter = ({ title, blogUrl }) => {
    const shareOnTwitter = () => {
        const encodedTitle = encodeURIComponent(title);
        const encodedUrl = encodeURIComponent(blogUrl);

        const tweetText = `Check out this awesome blog post: ${encodedTitle} ${encodedUrl}`;
        const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

        window.open(tweetUrl, '_blank');
    };
    
        return (
            <li><a href="javascript: void(0);" onClick={shareOnTwitter}  className="fa fa-twitter" /></li>
        );
};

export default ShareOnTwitter;
