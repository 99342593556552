import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/gallery/portrait/about1.webp'),
    require('./../../images/gallery/portrait/about2.webp'),
    require('./../../images/gallery/portrait/about3.webp'),
    require('./../../images/gallery/portrait/about4.webp'),
    require('./../../images/gallery/portrait/about5.webp'),
]

class About2 extends React.Component {
    
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: false,
        };
        return (
            <>
                <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="m-about m-l50 m-r50">
                                        <OwlCarousel className="owl-carousel about-us-carousel owl-btn-bottom-right" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                <div className="ow-img wt-img-effect zoom-slow">
                                                <NavLink to={""}><img src={item.default} alt="" /></NavLink>
                                                    </div>
                                                </div>
                                            ))}

                                        </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt text-uppercase text-black p-t30">
                                            <span className="font-30 font-weight-300">About Us</span>
                                            <h2 className="font-40">Your space, our expertise</h2>
                                            <p><b>At Under the arch, we believe that every space has the potential to become a masterpiece. We are a passionate team of dedicated architect & interior designers who are committed to transforming your spaces into captivating and functional works of art. </b></p>
                                            <p className="text-lowercase">With a keen eye for detail and a deep understanding of design principles, we pride ourselves on creating interiors that reflect the unique essence of each client. Our approach combines creativity, innovation, and a touch of magic to curate spaces that not only inspire awe but also elevate the way you live and work. 
                                            </p>
                                            <NavLink to={"/contactus"} className="site-button black radius-no text-uppercase"><span className="font-12 letter-spacing-5"> BOOK FREE CONSULTATION </span></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default About2;